import { ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE } from 'fhir/constants'

export const APPOINTMENT_CHANGE_REASON_EXTENSION = `${ABBYCARE_FHIR_STRUCTURE_DEFINITION_BASE}/appointment-change-reason`

export enum AppointmentType {
  CNA_VISIT = 'cna-visit',
  HHA_STANDARD_SHIFT = 'hha-standard-shift',
  HHA_FLEXIBLE_SHIFT = 'hha-flexible-shift',

  // These are pulled from `axlehealth/constants.py`
  START_OF_CARE = 'start-of-care',
  RECERTIFICATION = 'recertification',
  DELEGATION = 'delegation',
  SUPERVISORY_VISIT = 'supervisory-visit',
  VIRTUAL_RECERTIFICATION = 'virtual-recertification',
  ONE_YEAR_PAR = '1-year-par',
  RESUMPTION_OF_CARE = 'resumption-of-care',
  IN_PERSON_PAT = 'in-person-pat',
  ACUTE = 'acute'
}

export const CAREGIVER_VISIT_APPOINTMENT_TYPES = [
  AppointmentType.CNA_VISIT,
  AppointmentType.HHA_STANDARD_SHIFT,
  AppointmentType.HHA_FLEXIBLE_SHIFT
]

export enum AppointmentStatus {
  BOOKED = 'booked',
  FULFILLED = 'fulfilled',
  CANCELLED = 'cancelled',
  NOSHOW = 'noshow',
  ENTERED_IN_ERROR = 'entered-in-error'
}

export const AUTHORIZED_APPOINTMENT_STATUSES = [
  AppointmentStatus.BOOKED,
  AppointmentStatus.FULFILLED,
  AppointmentStatus.CANCELLED,
  AppointmentStatus.NOSHOW
]

export enum GeneralRNVisitType {
  ASSESSMENT = 'assessment'
}
